body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



@font-face {
  font-family: "semi-bold";
  src: url("../fonts/RobotoSlab-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "medium";
  src: url("../fonts/RobotoSlab-Medium.ttf");
}

@font-face {
  font-family: "8-bit";
  src: url("../fonts/PressStart2P-Regular.ttf");
}